import _objectSpread from "D:/2024/PansCMS/panscms_vue/node_modules/.pnpm/@babel+runtime@7.20.13/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.error.cause.js";
import User from 'lin/model/user';
import { mapActions } from 'vuex';
import { post, put } from 'lin/plugin/axios';
import VuePictureCropper, { cropper } from 'vue-picture-cropper';
export default {
  components: {
    VuePictureCropper: VuePictureCropper
  },
  props: {
    originalImage: {
      type: String
    },
    cropVisible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['switchCropVisible'],
  methods: _objectSpread(_objectSpread({}, mapActions(['setUserAndState'])), {}, {
    handleCrop: function handleCrop() {
      var _this = this;
      // 获取裁剪数据
      var blob = cropper.getBlob();
      // 构造为文件对象
      var file = new File([blob], 'avatar.jpg', {
        type: 'image/jpeg'
      });
      return post('/cms/file', {
        file: file
      }).then(function (res) {
        // 清空输入框
        if (!Array.isArray(res) || res.length !== 1) {
          _this.$message.error('头像上传失败, 请重试');
          return false;
        }
        return put('/cms/user/avatar', {
          avatar: res[0].path
        }).then(function (putRes) {
          if (putRes.code < window.MAX_SUCCESS_CODE) {
            _this.$message({
              type: 'success',
              message: '更新头像成功'
            });
            _this.switchStatus();
            // 触发重新获取用户信息
            return User.getInformation();
          }
          return Promise.reject(new Error('更新头像失败'));
        }).then(function (infoRes) {
          // eslint-disable-line
          // 尝试获取当前用户信息
          var user = infoRes;
          _this.setUserAndState(user);
        });
      });
    },
    switchStatus: function switchStatus() {
      this.$emit('switchCropVisible', false);
    }
  })
};